// this file is index.js in tut
// imports NewsList component (listing.js in tut)
import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import NewsList from "../components/news-list"
import Seo from "../components/seo"

const News = () => (
   <SubpageLayout>
      <Seo title="News" />
      <NewsList />
   </SubpageLayout>

)

export default News