// listing.js in tut
import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby'

const LISTING_QUERY = graphql`
  query NewsListing{
    allMarkdownRemark(sort: {
      order:DESC
      fields: [frontmatter___date]
    }) {
    edges {
      node {
          excerpt(pruneLength: 480) 
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
      }
    }
    }
  }
`

const NewsList = () => (
  <StaticQuery 
    query={LISTING_QUERY}
    render={({allMarkdownRemark}) => (
      allMarkdownRemark.edges.map(edge => (
        <article key={edge.node.frontmatter.slug}>
          <Link className="header" to={`/news/${edge.node.frontmatter.slug}`}>
            <h2>{edge.node.frontmatter.title}</h2>
          </Link>
          <p>{edge.node.frontmatter.date}</p>
          <p>{edge.node.excerpt}<span>&nbsp;</span><Link to={`/news/${edge.node.frontmatter.slug}`}>continue reading</Link></p>
          <p></p>
        </article>
      ))
    )}
  />

   
)

export default NewsList